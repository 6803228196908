/* start ach_form scroll top script */
// $(document).ready(function () {
//   $("#remove").click(function () {
//     $('html, body').animate({
//       scrollTop: $("#nav-evaluation").offset().top
//     }, 2000);
//   });
// });
/* end ach_form scroll top script */

// $(".flex-prev").click(function (event) {
//   event.preventDefault();
//   $("html, body").animate({ scrollTop: 0 }, "slow");
//   return false;
// });




/* Start Accordion script */
$(document).ready(function () {
  // Add minus icon for collapse element which is open by default
  $(".collapse.show").each(function () {
    $(this).prev(".card-header").find(".plus_minus").addClass("fa-minus").removeClass("fa-plus");
  });

  // Toggle plus minus icon on show hide of collapse element
  $(".collapse").on('show.bs.collapse', function () {
    $(this).prev(".card-header").find(".plus_minus").removeClass("fa-plus").addClass("fa-minus");
  }).on('hide.bs.collapse', function () {
    $(this).prev(".card-header").find(".plus_minus").removeClass("fa-minus").addClass("fa-plus");
  });
});
/* End Accordion script */

/*start lighbox script */
$(document).ready(function () {
  var $lightbox = $('#lightbox');

  $('[data-target="#lightbox"]').on('click', function (event) {
    var $img = $(this).find('img'),
      src = $img.attr('src'),
      alt = $img.attr('alt'),
      css = {
        'maxWidth': $(window).width() - 100,
        'maxHeight': $(window).height() - 100
      };

    $lightbox.find('.close').addClass('hidden');
    $lightbox.find('img').attr('src', src);
    $lightbox.find('img').attr('alt', alt);
    $lightbox.find('img').css(css);
  });

  $lightbox.on('shown.bs.modal', function (e) {
    var $img = $lightbox.find('img');

    $lightbox.find('.modal-dialog').css({ 'width': $img.width() });
    $lightbox.find('.close').removeClass('hidden');
  });
});
/* end lightbox script */

/* start payment script */
$('#r11').on('click', function () {
  $(this).parent().find('a').trigger('click')
})

$('#r12').on('click', function () {
  $(this).parent().find('a').trigger('click')
})
/* end payment script */
/*--Header--*/
$('body').tooltip({
  selector: '[data-toggle="tooltip"], [title]:not([data-toggle="popover"])',
  trigger: 'hover',
  container: 'body'
}).on('click mousedown mouseup mouseleave', '[data-toggle="tooltip"], [title]:not([data-toggle="popover"])', function () {
  $('[data-toggle="tooltip"], [title]:not([data-toggle="popover"])').tooltip('dispose');
});

$('.apply_now').click(function () {
  $('.screen_menu').addClass("active");
  $('body').addClass("overflow_body");
});
$(".screen_menu #close_career").click(function () {
  $('.screen_menu').removeClass("active");
  $('body').removeClass("overflow_body");
});
$(".search_block i.fa-search").click(function () {
  $(".search_block input").val("");
  $(".search_block").addClass("activeinput");
});
$('.search_block .fa-times').click(function () {
  $(".search_block").removeClass("activeinput");
});
$(document).ready(function () {
  //alert($(window).height());
  const body = document.querySelector('body');

  body.style.setProperty('--login-screen-height', $(window).height() + "px");
});

/*--End Header--*/

/*----- evaluators popup script -----*/
$('#evaluator1').click(function () {
  $('#evaluators_wrapper1').addClass("active");
  $('body').addClass("overflow_body");
});

$("#evaluators_wrapper1 .close-menu").click(function () {
  $('#evaluators_wrapper1').removeClass("active");
  $('body').removeClass("overflow_body");
});

$('#evaluator2').click(function () {
  $('#evaluators_wrapper2').addClass("active");
  $('body').addClass("overflow_body");
});

$("#evaluators_wrapper2 .close-menu").click(function () {
  $('#evaluators_wrapper2').removeClass("active");
  $('body').removeClass("overflow_body");
});
/*----- end evaluators popup script -----*/

/* -- Footer --*/
var currentYear = (new Date()).getFullYear();
$("#footer_year").html(currentYear);
/* --End Footer --*/

/*edit-profile script*/
$("#editimage").change(function (event) {
  $('#editprofile_img').show();
  readURLedit(this);
});
$('#editprofile_img').hide();
function readURLedit(input) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();
    var filename = $("#editimage").val();
    filename = filename.substring(filename.lastIndexOf('\\') + 1);
    reader.onload = function (e) {
      $('#blah').attr('src', e.target.result);
      $('#blah').hide();
      $('#blah').fadeIn(500);
    }
    reader.readAsDataURL(input.files[0]);
  }
}

$("#editprofile_img").click(function () {
  $("#editimage").val('');
  $('#blah').attr('src', 'images/placeholder.png');
  $('#editprofile_img').hide();
});
/*edit-profile script*/

/*Form script*/
$("#edit_pencil").hide();
$("#edit_download").hide();
$("#inputGroupFile01").change(function (event) {
  RecurFadeIn();
  readURL(this);
});
$("#inputGroupFile01").on('click', function (event) {
  RecurFadeIn();
});
function readURL(input) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();
    var filename = $("#inputGroupFile01").val();
    filename = filename.substring(filename.lastIndexOf('\\') + 1);
    reader.onload = function (e) {
      $('#blah').attr('src', e.target.result);
      $('#blah').addClass('uploadimg');
      $('#blah').hide();
      $('#blah').fadeIn(500);
      $('#custom-file-label').text(filename);
    }
    reader.readAsDataURL(input.files[0]);
    $("#edit_pencil").show();
    $("#edit_download").show();
  }
  $(".alert").removeClass("loading").hide();
}
$("#edit_pencil").click(function () {
  $("#inputGroupFile01").val('');
  $('#blah').attr('src', 'images/upload.png');
  $('#blah').removeClass('uploadimg');
  $('#custom-file-label').text('');
  $("#edit_pencil").hide();
  $("#edit_download").hide();
});
function RecurFadeIn() {
  console.log('ran');
  // FadeInAlert("Wait for it...");
}
function FadeInAlert(text) {
  $(".alert").show();
  $(".alert").text(text).addClass("loading");
}
/*Form script*/

/*--- assessment complete script ---*/

$('.prev').on('click', function () {
  $('#slider').flexslider('prev')
  return false;
})

$('.next').on('click', function () {
  $('#slider').flexslider('next')
  return false;
})


$(".close_assessment").click(function () {
  $('.assessment_wrap_top').toggleClass('expanded_block');
  $('.close_assessment i').toggleClass('fa-angle-down');
  $('.close_assessment i').toggleClass('fa-angle-up');
});
$('#assessmentprogress_circle').circleProgress({
  value: 0.75,
  size: 100.0,
  emptyFill: '#ccc',
  fill: { gradient: ['#A6CE39', '#0061AF'] }
}).on('circle-animation-progress', function (event, progress) {
  $(this).find('strong').html('<span>75%</span>75/100<br />Attempts');
});

$('.dropdown-el').click(function (e) {
  e.preventDefault();
  e.stopPropagation();
  $(this).toggleClass('expanded');
  $('#' + $(e.target).attr('for')).prop('checked', true);
});
$(document).click(function () {
  $('.dropdown-el').removeClass('expanded');
});

/*--- end assessment complete script ---*/

/*----- assessment popup script -----*/
$('.questionblock').click(function () {
  $('.assessment_que_wrapper').addClass("active");
  $('body').addClass("overflow_body");
});

$(".assessment_que_wrapper .close-menu").click(function () {
  $('.assessment_que_wrapper').removeClass("active");
  $('body').removeClass("overflow_body");
});
/*----- end assessment popup script -----*/

/*--Input Form --*/
$('.rate_stars input[type="radio"]').change(function (e) {
  $('.rating_input').val(this.value);
});

$('.form_datetime').datetimepicker({
  weekStart: 1,
  todayBtn: 1,
  autoclose: 1,
  todayHighlight: 1,
  startView: 2,
  forceParse: 0,
  format: 'mm-dd-yyyy HH:ii p',
  showMeridian: true,
});

$('.form_date').datetimepicker({
  weekStart: 1,
  todayBtn: 1,
  autoclose: 1,
  todayHighlight: 1,
  startView: 2,
  minView: 2,
  forceParse: 0,
  pickTime: false,
  format: 'mm-dd-yyyy',
});

$('.form_time').datetimepicker({
  weekStart: 1,
  todayBtn: 0,
  autoclose: 1,
  startView: 1,
  maxView: 1,
  forceParse: 1,
  format: 'HH:ii p',
  pickDate: false,
  showMeridian: true,
}).on('hide', function (ev) {
  $(".datetimepicker .prev").attr("style", "visibility:visible");
  $(".datetimepicker .next").attr("style", "visibility:visible");
  $(".switch").attr("style", "pointer-events: auto");
});
$(".form_time").click(function () {
  $(".datetimepicker .prev").attr("style", "visibility:hidden");
  $(".datetimepicker .next").attr("style", "visibility:hidden");
  $(".switch").attr("style", "pointer-events: none");
});

$('.select2').select2();

$('.multiselectdropdown').multiselect({
  includeSelectAllOption: true,
  enableFiltering: true
});

var substringMatcher = function (strs) {
  return function findMatches(q, cb) {
    var matches, substringRegex;
    matches = [];
    substrRegex = new RegExp(q, 'i');
    $.each(strs, function (i, str) {
      if (substrRegex.test(str)) {
        matches.push(str);
      }
    });

    cb(matches);
  };
};
var states = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California',
  'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii',
  'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana',
  'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota',
  'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire',
  'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota',
  'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island',
  'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont',
  'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
];

$('.autocomplete').typeahead({
  hint: true,
  highlight: true,
  minLength: 1
},
  {
    name: 'states',
    source: substringMatcher(states)
  });

// var citynames = new Bloodhound({
// datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
// queryTokenizer: Bloodhound.tokenizers.whitespace,
// prefetch: {
// url: 'citynames.json',
// filter: function (list) {
// return $.map(list, function (cityname) {
// return { name: cityname };
// });
// }
// }
// });
// citynames.initialize();

// $('#tagsinput').tagsinput({
// typeaheadjs: {
// name: 'citynames',
// displayKey: 'name',
// valueKey: 'name',
// source: citynames.ttAdapter()
// }
// });

$('.file_upload input[type="file"]').change(function (e) {
  var fileName = e.target.files[0].name;
  $('.file_upload input[type="text"]').val(fileName);
});

$('#multiupload').imageuploadify();

// Basic instantiation:
$('#demo').colorpicker();

// Example using an event, to change the color of the .jumbotron background:
$('#demo').on('colorpickerChange', function (event) {
  $('.color_picker').css('background-color', event.color.toString());
});

// $("#table").on('click', '.add', function () {
//   rowObj = $("#table tr:first").clone();
//   $("#table").append(rowObj);
// });
// $("#table").on('click', '.remove', function () {
//   $(this).parent().parent().remove();
// });
/*--End Input Form --*/

// end  script for tab steps
/*--SideBar --*/
$('.sidebar_toggle button').click(function () {
  $('.sidebar_toggle').toggleClass("active");
  $('.sidebar_wrap').toggleClass("small_menu");
  $('.main_block').toggleClass("large_content");
});
/*--End SideBar --*/


/*--User Dashboard --*/
$('#dashboard_box1 .skip_btn').click(function () {
  $('#dashboard_box1 .skiped_block').addClass("active");
});
$('#dashboard_box1 .skiped_block .close_skip').click(function () {
  $('#dashboard_box1 .skiped_block').removeClass("active");
});
$('#dashboard_box1 .extend_btn').click(function () {
  $('#dashboard_box1 .extended_block').addClass("active");
});
$('#dashboard_box1 .extended_block .close_skip').click(function () {
  $('#dashboard_box1 .extended_block').removeClass("active");
});
$('#dashboard_box1 .unskip_btn').click(function () {
  $('#dashboard_box1 .unskiped_block').addClass("active");
});
$('#dashboard_box1 .unskiped_block .close_unskip').click(function () {
  $('#dashboard_box1 .unskiped_block').removeClass("active");
});
$('#dashboard_box1 .unskiped_block .close_unskip').click(function () {
  $('#dashboard_box1 .unskiped_block').removeClass("active");
});

/*--End User Dashboard --*/

